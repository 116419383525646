import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import {
  IMatrixGalleryOwnSDK,
  IMatrixGalleryProps,
  IMatrixGallerySDKFactory,
} from '../MatrixGallery.types';
import {
  clickActionSDKFactory,
  createElementPropsSDKFactory,
  onItemClickSDKFactory,
  unsupportedPlayablePropsSDKFactory,
  unsupportedCurrentItemPropsSDKFactory,
  toJSONBase,
} from '../../../../core/corvid/props-factories';
import {
  convertGalleryItemsToUserModel,
  convertToGalleryItemsPropsOrReport,
  createUnsupportedAPIReporter,
} from '../../../../core/corvid/galleries/GallerySDKUtils';

const matrixGallerySDKFactory: IMatrixGallerySDKFactory = api => {
  const {
    sdkData,
    props,
    metaData,
    setProps,
    platformUtils: { linkUtils },
  } = api;
  const reportUnsupportedAPI = createUnsupportedAPIReporter('MatrixGallery');
  const clickActionSDK = clickActionSDKFactory(api);

  const sdkProps: IMatrixGalleryOwnSDK = {
    get clickAction() {
      return clickActionSDK.clickAction;
    },
    set clickAction(clickAction) {
      clickActionSDK.clickAction = clickAction;
    },
    get items() {
      return convertGalleryItemsToUserModel(props.items, linkUtils);
    },
    set items(items) {
      const propsItems = convertToGalleryItemsPropsOrReport(
        items,
        metaData.role,
        metaData.compId,
        sdkData.dataId,
        linkUtils,
        sdkData.imageDisplayMode,
      );
      if (propsItems) {
        setProps({ items: propsItems });
      }
    },
    get galleryCapabilities() {
      return {
        hasCurrentItem: false,
        hasNavigationButtons: false,
        isAnimatable: true,
        isPlayable: false,
        supportsAllMediaTypes: false,
      };
    },
    get showNavigationButtons() {
      return reportUnsupportedAPI('showNavigationButtons');
    },
    set showNavigationButtons(showNavigationButtons) {
      reportUnsupportedAPI('showNavigationButtons');
    },
    get type() {
      return '$w.Gallery';
    },
    toJSON() {
      return {
        ...toJSONBase(metaData),
        clickAction: this.clickAction,
        items: convertGalleryItemsToUserModel(props.items, linkUtils),
        type: '$w.Gallery',
      };
    },
  };

  return sdkProps;
};

const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk = composeSDKFactories<
  IMatrixGalleryProps,
  IMatrixGalleryOwnSDK
>(
  elementPropsSDKFactory,
  onItemClickSDKFactory,
  unsupportedPlayablePropsSDKFactory,
  unsupportedCurrentItemPropsSDKFactory,
  matrixGallerySDKFactory,
);

export default createComponentSDKModel(sdk);
