import { IClassicSectionCorvidModel } from '../ClassicSection.types';

const entry: IClassicSectionCorvidModel = {
  componentType: 'ClassicSection',
  loadSDK: () =>
    import(
      './ClassicSection.corvid' /* webpackChunkName: "ClassicSection.corvid" */
    ),
};

export default entry;
