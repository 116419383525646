import {
  composeSDKFactories,
  createElementPropsSDKFactory,
  toJSONBase,
  withValidation,
  reportError,
  registerCorvidEvent,
} from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import { CorvidState, VideoBoxSDKFactory } from '../VideoBox.types';
import {
  BG_VIDEO_DEFAULTS,
  getFullMediaData,
  getMediaDataFromSrc,
} from '../../../../core/corvid/media/backgroundUtils';
import * as mediaItemUtils from '../../../../core/corvid/media/mediaItemUtils';
import { createMediaSrc } from '../../../../core/corvid/media/mediaSrcHandler';

const _sdkFactory: VideoBoxSDKFactory = api => {
  const { props, setProps, compRef, registerEvent, metaData, createSdkState } =
    api;

  const [state, setState] = createSdkState({
    isMuted: props.muted,
    isPlaying: false,
    currentTime: 0,
    duration: 0,
    volume: 100,
    shouldPlay: props.autoplay && !props.reducedMotion,
  });

  registerEvent('updateState', (updates: Partial<CorvidState>) =>
    setState(updates),
  );

  return {
    get isMuted() {
      return state.isMuted;
    },

    get isPlaying() {
      return state.isPlaying;
    },

    get currentTime() {
      return state.currentTime;
    },

    get duration() {
      return state.duration;
    },

    get volume() {
      return state.volume;
    },

    set volume(volume: number) {
      setState({ volume });

      compRef.setVolume(volume / 100);
    },

    get src() {
      const { fillLayers } = props;

      if (fillLayers?.video?.videoInfo?.videoId) {
        const { videoInfo } = fillLayers.video;
        const mediaItemUri = createMediaSrc({
          mediaId: videoInfo.videoId,
          type: mediaItemUtils.types.VIDEO,
          width: videoInfo.videoWidth,
          height: videoInfo.videoHeight,
          posterId: fillLayers.video.posterImageInfo.uri,
        });

        if (mediaItemUri.error) {
          return '';
        }

        return mediaItemUri.item || '';
      }

      return '';
    },

    set src(value: string) {
      const { fillLayers = {} } = props;
      const mediaData = getMediaDataFromSrc(value);

      if (!mediaData) {
        reportError(
          `The "src" property cannot be set to "${value}". It must be a valid video URL starting with "wix:video://".`,
        );
        return;
      }

      if (fillLayers.video?.videoInfo?.videoId === mediaData.videoId) {
        // no change => bail out
        return;
      }

      getFullMediaData(mediaData, fullMediaRefData => {
        if (!fullMediaRefData) {
          return;
        }

        const isTransparent =
          fullMediaRefData.mediaObject.mediaFeatures?.includes('alpha');

        setProps({
          fillLayers: {
            ...fillLayers,
            video: {
              ...fillLayers.video,
              alt: '',
              posterImageInfo: {
                ...fillLayers?.video?.posterImageInfo,
                ...mediaData.posterImageRef,
              },
              videoInfo: {
                ...fillLayers?.video?.videoInfo,
                videoId: fullMediaRefData.mediaObject.videoId,
                videoWidth: fullMediaRefData.mediaObject.videoWidth,
                videoHeight: fullMediaRefData.mediaObject.videoHeight,
                qualities: fullMediaRefData.mediaObject.qualities
                  .filter(
                    (item: Record<string, any>) =>
                      item.quality !== 'storyboard',
                  )
                  .sort(
                    (a: Record<string, any>, b: Record<string, any>) =>
                      parseInt(a.quality, 10) - parseInt(b.quality, 10),
                  ),
                isVideoDataExists: '1',
                videoFormat: fullMediaRefData.mediaObject.videoFormat,
                playbackRate: fullMediaRefData.mediaObject.playbackRate,
                autoPlay: state.shouldPlay,
                hasAlpha: isTransparent,
              },
              muted: state.isMuted,
            },
          },
          hasAudio: fullMediaRefData.mediaObject.hasAudio,
          isTransparent,
        });
      });

      // set new poster (a.k.a video partial props)
      setProps({
        fillLayers: {
          ...fillLayers,
          video: {
            ...BG_VIDEO_DEFAULTS,
            muted: state.isMuted !== undefined ? state.isMuted : props.muted,
            alt: '',
            posterImageInfo: {
              containerId: metaData.compId,
              ...mediaData.posterImageRef,
            },
            videoInfo: {
              containerId: metaData.compId,
              videoId: mediaData.videoId,
              isVideoDataExists: false,
            },
          },
        },
        hasAudio: false,
        isTransparent: false,
      });
    },

    play() {
      return compRef.play(true);
    },

    pause() {
      return compRef.pause();
    },

    stop() {
      return compRef.stop();
    },

    togglePlay() {
      return compRef.togglePlay();
    },

    mute() {
      return compRef.mute();
    },

    unmute() {
      return compRef.unmute();
    },

    onPlay: handler => registerCorvidEvent('onPlay', api, handler),

    onPause: handler => registerCorvidEvent('onPause', api, handler),

    onEnded: handler => registerCorvidEvent('onEnded', api, handler),

    onProgress: handler => registerCorvidEvent('onProgress', api, handler),

    toJSON() {
      return {
        ...toJSONBase(metaData),
        ...state,
      };
    },
  };
};

const sdkFactory = withValidation(_sdkFactory, {
  type: ['object'],
  properties: {
    volume: {
      type: ['number'],
      minimum: 0,
      maximum: 100,
      warnIfNil: true,
    },
    src: {
      type: ['string'],
      warnIfNil: true,
    },
  },
});

const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk: VideoBoxSDKFactory = composeSDKFactories(
  elementPropsSDKFactory,
  sdkFactory,
);

export default createComponentSDKModel(sdk);
