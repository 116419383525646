import { IMultiStateBoxCorvidModel } from '../MultiStateBox.types';

const entry: IMultiStateBoxCorvidModel = {
  componentType: 'MultiStateBox',
  sdkType: 'MultiStateBox',
  loadSDK: () =>
    import(
      './MultiStateBox.corvid' /* webpackChunkName: "MultiStateBox.corvid" */
    ),
};

export default entry;
