import {
  withValidation,
  composeSDKFactories,
  createElementPropsSDKFactory,
  changePropsSDKFactory,
  clickPropsSDKFactory,
  assert,
  childrenPropsSDKFactory,
  toJSONBase,
  isValidStateReference,
} from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import {
  IMultiStateBoxSDKFactory,
  IMultiStateBoxStateSDK,
  IMultiStateBoxProps,
  IMultiStateBoxSDK,
  IBaseMultiStateBoxStateSDK,
} from '../MultiStateBox.types';

const createStateSDKInstance = (
  state: IBaseMultiStateBoxStateSDK,
): IBaseMultiStateBoxStateSDK => {
  const sdk = {
    ...state,
    get type() {
      return '$w.State';
    },
    toJSON() {
      return {
        ...state.toJSON(),
        type: sdk.type,
      };
    },
  };

  return sdk;
};

const stateBoxSDKFactory: IMultiStateBoxSDKFactory = api => {
  const { props, getChildren, metaData, setProps } = api;

  /** When MSB is in repeater, the state uniqueId returned from Velo is specific to the repeater item,
   * we want to get the general state id without the affixes added by repeater */
  const getStateId = (stateInstance: IBaseMultiStateBoxStateSDK) => {
    const { parent, role, id } = stateInstance;
    const stateMetadata = parent?.children.find(
      (state: IBaseMultiStateBoxStateSDK) => state.role === role,
    );
    const stateId = stateMetadata?.uniqueId;
    return stateId || id;
  };

  const sdk = {
    get currentState() {
      const states = sdk.states;
      const selectedState = states.find(
        state => getStateId(state) === props.selectedStateId,
      );
      const instance = selectedState ? selectedState : states[0];
      return createStateSDKInstance(instance);
    },
    get states() {
      return getChildren().map(createStateSDKInstance);
    },
    changeState(stateReference: string | IMultiStateBoxStateSDK) {
      const states = getChildren();
      const currentStateId = props.selectedStateId;
      const nextState = assert.isString(stateReference)
        ? states.find(({ role }) => role === stateReference)
        : stateReference;
      const nextStateId = nextState ? getStateId(nextState) : null;
      if (currentStateId === nextStateId) {
        return Promise.resolve(this.currentState);
      }
      return new Promise<IMultiStateBoxStateSDK>(resolve => {
        setProps({ selectedStateId: nextStateId });
        resolve(nextState);
      });
    },
    get type() {
      return '$w.MultiStateBox';
    },
    toJSON() {
      return {
        ...toJSONBase(metaData),
        type: sdk.type,
        currentStateId: sdk.currentState.id,
      };
    },
  };
  return sdk;
};

const customRules = {
  changeState: [isValidStateReference],
};

const stateBox = withValidation(
  stateBoxSDKFactory,
  {
    type: ['object'],
    properties: {
      changeState: {
        type: ['function'],
        args: [{ type: ['object', 'string'] }],
      },
    },
  },
  customRules,
);

export const sdk = composeSDKFactories<IMultiStateBoxProps, IMultiStateBoxSDK>(
  createElementPropsSDKFactory(),
  changePropsSDKFactory,
  clickPropsSDKFactory,
  childrenPropsSDKFactory,
  stateBox,
);

export default createComponentSDKModel(sdk);
