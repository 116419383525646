import { TIME_SUFFIX } from './constants';

/**
 * @example
 * Output: 15:26:00.000
 */
export const getLongTimeFromDate = (date: Date) => {
  return `${date.toTimeString().substr(0, 5)}${TIME_SUFFIX}`;
};

/**
 * @example
 * Input: 15:26:13:123
 * Output: 15:26
 */
export const getShortTime = (longTime?: string | null) => {
  return !longTime ? longTime : longTime.substr(0, 5);
};

/**
 * @example
 * Input: 15:26
 * Output: 15:26:00.000
 */
export const getLongTime = (shortTime?: string | null) => {
  return !shortTime ? shortTime : `${shortTime}${TIME_SUFFIX}`;
};

const formatTimePart = (part: number) => part.toString().padStart(2, '0');

/**
 * @example
 * Input: 15:26
 * Output: 03:26 PM
 * Input: 03:26
 * Output: 03:26 AM
 */
export const getAmpmTime = (shortTime?: string | null) => {
  if (!shortTime) {
    return shortTime;
  }
  return shortTime.replace(/(\d\d):(\d\d)/, (_m, hoursGroup, minutesGroup) => {
    const hours = parseInt(hoursGroup, 10);
    if (hours < 12) {
      const fixedHours = hours === 0 ? 12 : hours;
      const hoursStr = formatTimePart(fixedHours);
      return `${hoursStr}:${minutesGroup} AM`;
    } else {
      const subtractedHours = hours - 12;
      const fixedHours = subtractedHours === 0 ? 12 : subtractedHours;
      const hoursStr = formatTimePart(fixedHours);
      return `${hoursStr}:${minutesGroup} PM`;
    }
  });
};

export const getAvailableTimesPerDay = (step: number) => {
  const length = Math.floor(1440 / step) + (1440 % step ? 1 : 0);
  return Array(length)
    .fill(0)
    .map((_, index) => {
      const totalMinutes = index * step;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes - hours * 60;

      const hoursStr = formatTimePart(hours);
      const minutesStr = formatTimePart(minutes);

      const isAm = hours < 12;
      const subtractedHours = isAm ? hours : hours - 12;
      const fixedHours = subtractedHours === 0 ? 12 : subtractedHours;
      const ampmHoursStr = formatTimePart(fixedHours);
      const ampm = isAm ? 'AM' : 'PM';

      return {
        time: `${hoursStr}:${minutesStr}`,
        ampmTime: `${ampmHoursStr}:${minutesStr} ${ampm}`,
      };
    });
};

const timeRegex =
  /^([0-1][0-9]|2[0-3]):([0-5][0-9])(:([0-5][0-9])(\.[0-9]{3})?)?$/;

export const isValidTimeFormat = (time?: string | null) => {
  return !time ? false : timeRegex.test(time);
};

/**
 * @example
 * Input: 15:26
 * Output: 15:26:00.000
 * Input: 15:26:33
 * Output: 15:26:33.000
 * Input: 15:26:33.111
 * Output: 15:26:33.111
 */
export const completeToLongTime = (time?: string | null) => {
  return !time ? time : `${time}${TIME_SUFFIX.substr(time.length - 5)}`;
};

export const getCurrentTimeValue = () =>
  `${new Date(Date.now()).toTimeString().slice(0, 5)}:00.000`;
