import { VideoLegacyProps } from '@wix/thunderbolt-components-native';
import { reportError } from '@wix/editor-elements-corvid-utils';
import { IVideoData } from '../../VideoLegacy.types';

const BASE_URLS = {
  YOUTUBE: 'https://www.youtube.com/watch?v=',
  VIMEO: 'https://vimeo.com/',
  DAILYMOTION: 'https://dailymotion.com/video/',
  FACEBOOK: 'https://www.facebook.com/',
};

export const getVideoUrl = ({ videoType, videoId }: IVideoData) => {
  if (videoType && videoId) {
    return BASE_URLS[videoType] + videoId;
  }

  return '';
};

export const getVideoDataFromUrl = (videoUrl: string) => {
  const players: Array<[VideoLegacyProps['videoType'], RegExp]> = [
    ['YOUTUBE', /(?:youtube\.com\/watch[^\s]*[?&]v=)([\w-]+)/g],
    ['YOUTUBE', /(?:youtu\.be\/)([\w-]+)/g],
    ['VIMEO', /vimeo\.com\/(\d+)$/gi],
    ['DAILYMOTION', /dailymotion\.com\/(?:video|playlist)\/([^_/]+)/gi],
    ['FACEBOOK', /facebook\.com\/(.+\/videos\/[A-Za-z0-9._%-]*)/gi],
  ];
  let videoData: IVideoData = {};

  players.every(([videoType, regex]) => {
    const match = regex.exec(videoUrl);

    if (match && match.length && match[1]) {
      // if there is a match, the second group is the id we want
      videoData = { videoType, videoId: match[1] };
      return false;
    }
    return true;
  });

  return videoData;
};

export const isValidUrl = (urlToTest: string) => {
  const regexp =
    /^(?:(?:https?:)\/\/)(?:(?:[\u0400-\uA69F\w][\u0400-\uA69F\w-]*)?[\u0400-\uA69F\w]\.)+(?:[\u0400-\uA69Fa-z]+|\d{1,3})(?::[\d]{1,5})?(?:[/?#].*)?$/i;

  if (regexp.test(urlToTest)) {
    return true;
  } else {
    reportError(
      `The videoUrl parameter that is passed to the videoUrl method cannot be set to the value . It must be a valid URL`,
    );
    return false;
  }
};
