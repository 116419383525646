import {
  composeSDKFactories,
  toJSONBase,
  withValidation,
  reportError,
  messages,
  messageTemplates,
  CustomPropertyValidator,
} from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import {
  createElementPropsSDKFactory,
  createStylePropsSDKFactory,
} from '../../../core/corvid/props-factories';
import {
  IProgressBarProps,
  IProgressBarSDK,
  IProgressBarSDKFactory,
} from '../ProgressBar.types';

const progressBarSDKFactory: IProgressBarSDKFactory = ({
  setProps,
  props,
  metaData,
}) => ({
  get value() {
    return props.value;
  },

  set value(value) {
    setProps({ value });
  },

  get targetValue() {
    return props.targetValue ?? 100;
  },

  set targetValue(value) {
    setProps({ targetValue: value });
  },

  get type() {
    return '$w.ProgressBar';
  },

  toJSON() {
    return {
      ...toJSONBase(metaData),
      type: '$w.ProgressBar',
      value: props.value,
      targetValue: props.targetValue ?? 100,
    };
  },
});

const validateLargerThanZero =
  (
    propertyName: string,
  ): CustomPropertyValidator<IProgressBarProps, IProgressBarSDK> =>
  value => {
    const isValid = value > 0;

    if (!isValid) {
      reportError(
        messageTemplates.error_larger_than({
          functionName: propertyName,
          propertyName,
          minimum: 0,
          value,
        }),
      );
    }

    return isValid;
  };

const validateAtLeastZero =
  (
    propertyName: string,
  ): CustomPropertyValidator<IProgressBarProps, IProgressBarSDK> =>
  value => {
    const isValid = value >= 0;

    if (!isValid) {
      reportError(
        messageTemplates.error_at_least({
          functionName: propertyName,
          propertyName,
          minimum: 0,
          value,
        }),
      );
    }

    return isValid;
  };

const validateNotInfinity =
  (
    propertyName: string,
  ): CustomPropertyValidator<IProgressBarProps, IProgressBarSDK> =>
  value => {
    const isValid = isFinite(value);

    if (!isValid) {
      reportError(
        messages.invalidTypeMessage({
          functionName: propertyName,
          propertyName,
          types: ['number'],
          value,
          index: undefined,
        }),
      );
    }

    return isValid;
  };

const progressBar = withValidation(
  progressBarSDKFactory,
  {
    type: ['object'],
    properties: {
      value: {
        type: ['number'],
      },
      targetValue: {
        type: ['number'],
      },
    },
  },
  {
    value: [validateNotInfinity('value'), validateAtLeastZero('value')],
    targetValue: [
      validateNotInfinity('targetValue'),
      validateLargerThanZero('targetValue'),
    ],
  },
);

const elementPropsSDKFactory = createElementPropsSDKFactory();

const stylePropsSDKFactory = createStylePropsSDKFactory({
  BackgroundColor: true,
  ForegroundColor: true,
  BorderColor: true,
  BorderWidth: true,
  BorderRadius: true,
  TextColor: true,
});

export const sdk = composeSDKFactories<IProgressBarProps, IProgressBarSDK>(
  elementPropsSDKFactory,
  stylePropsSDKFactory,
  progressBar,
);

export default createComponentSDKModel(sdk);
