import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import { CorvidTypes } from '@wix/editor-elements-types';
import { tpaGalleryPropsSDKFactory } from '../../TPAGallery/corvid/TPAGallerySDK';
import { tpaGalleryWarningSDKFactory } from '../../TPAGallery/corvid/TPAGalleryWarningSDK';
import {
  TPAGallerySDK,
  TPAGalleryProps,
  TPAGalleryCapabilitiesSDK,
} from '../../TPAGallery/TPAGallery.types';

const capabilities = {
  isPlayable: false,
  hasCurrentItem: false,
  hasNavigationButtons: false,
  supportsAllMediaTypes: false,
  isAnimatable: true,
};

export const ownSDKFactory: CorvidTypes.CorvidSDKFactory<TPAGalleryCapabilitiesSDK> =
  () => ({
    get galleryCapabilities() {
      return capabilities;
    },
  });

export const sdk = composeSDKFactories<TPAGalleryProps, TPAGallerySDK>(
  tpaGalleryPropsSDKFactory,
  tpaGalleryWarningSDKFactory(capabilities),
  ownSDKFactory,
);

export default createComponentSDKModel(sdk);
