import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import { CorvidTypes } from '@wix/editor-elements-types';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import { tpaGalleryPropsSDKFactory } from '../../TPAGallery/corvid/TPAGallerySDK';
import { tpaGalleryCurrentItemSDKFactory } from '../../TPAGallery/corvid/TPAGalleryCurrentItemSDK';
import { tpaGalleryPlayableSDKFactory } from '../../TPAGallery/corvid/TPAGalleryPlayableSDK';
import { tpaGalleryWarningSDKFactory } from '../../TPAGallery/corvid/TPAGalleryWarningSDK';
import { tpaGalleryNavigationButtonsSDKFactory } from '../../TPAGallery/corvid/TPAGalleryNavigationButtonsSDK';
import {
  TPAGallerySDK,
  TPAGalleryProps,
  TPAGalleryCapabilitiesSDK,
} from '../../TPAGallery/TPAGallery.types';

const capabilities = {
  isPlayable: true,
  hasCurrentItem: true,
  hasNavigationButtons: true,
  supportsAllMediaTypes: false,
  isAnimatable: true,
};

export const ownSDKFactory: CorvidTypes.CorvidSDKFactory<TPAGalleryCapabilitiesSDK> =
  () => ({
    get galleryCapabilities() {
      return capabilities;
    },
  });

export const sdk = composeSDKFactories<TPAGalleryProps, TPAGallerySDK>(
  tpaGalleryPropsSDKFactory,
  tpaGalleryCurrentItemSDKFactory,
  tpaGalleryPlayableSDKFactory,
  tpaGalleryNavigationButtonsSDKFactory,
  tpaGalleryWarningSDKFactory(capabilities),
  ownSDKFactory,
);

export default createComponentSDKModel(sdk);
