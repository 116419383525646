import {
  withValidation,
  composeSDKFactories,
} from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import {
  IDatePickerProps,
  IDatePickerOwnSDKFactory,
  IDatePickerSDK,
  IDatePickerImperativeActions,
} from '../DatePicker.types';
import {
  readOnlyPropsSDKFactory,
  createValidationPropsSDKFactory,
  createRequiredPropsSDKFactory,
  focusPropsSDKFactory,
  disablePropsSDKFactory,
  clickPropsSDKFactory,
  changePropsSDKFactory,
  createElementPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';
import {
  createInputValidator,
  InputValidator,
  composeValidators,
  validateRequired,
} from '../../../core/corvid/inputUtils';
import { getStartOfDay } from '../../../core/commons/dateUtils';

const datePickerValidator: InputValidator<
  IDatePickerProps,
  IDatePickerImperativeActions
> = createInputValidator(
  composeValidators<IDatePickerProps>([validateRequired]),
);

const validationPropsSDKFactory = createValidationPropsSDKFactory<
  IDatePickerProps,
  IDatePickerImperativeActions
>(datePickerValidator);

const requiredPropsSDKFactory = createRequiredPropsSDKFactory<
  IDatePickerProps,
  IDatePickerImperativeActions
>(datePickerValidator);

const _ownSDKFactory: IDatePickerOwnSDKFactory = api => {
  const { props, setProps, metaData } = api;

  const sdkProps = {
    get value() {
      // TODO: remove this if after TB-939 is fixed
      if (props.useTodayAsDefaultValue && !props.value) {
        return getStartOfDay(new Date(Date.now()));
      }
      return props.value || null;
    },
    set value(value) {
      setProps({
        value,
      });

      datePickerValidator.validate({
        viewerSdkAPI: api,
        showValidityIndication: true,
      });
    },
    get maxDate() {
      return props.maxDate ? new Date(props.maxDate) : null;
    },
    set maxDate(maxDate) {
      setProps({
        maxDate: maxDate?.toISOString(),
      });
    },
    get minDate() {
      return props.minDate ? new Date(props.minDate) : null;
    },
    set minDate(minDate) {
      setProps({
        minDate: minDate?.toISOString(),
      });
    },
    get disabledDates() {
      return props.disabledDates.map(date => new Date(date));
    },
    set disabledDates(disabledDates) {
      setProps({
        disabledDates: (disabledDates || []).map(date => date.toISOString()),
      });
    },
    get disabledDaysOfWeek() {
      return props.disabledDaysOfWeek;
    },
    set disabledDaysOfWeek(disabledDaysOfWeek) {
      setProps({
        disabledDaysOfWeek: disabledDaysOfWeek || [],
      });
    },
    toJSON() {
      const { readOnly, required } = props;
      const { value, maxDate, minDate, disabledDates, disabledDaysOfWeek } =
        sdkProps;
      return {
        ...toJSONBase(metaData),
        readOnly,
        required,
        value,
        maxDate,
        minDate,
        disabledDates,
        disabledDaysOfWeek,
      };
    },
  };

  return sdkProps;
};

const ownSDKFactory = withValidation(_ownSDKFactory, {
  type: ['object'],
  properties: {
    value: {
      type: ['date', 'nil'],
    },
    maxDate: {
      type: ['date', 'nil'],
    },
    minDate: {
      type: ['date', 'nil'],
    },
    disabledDates: {
      type: ['array', 'nil'],
      items: {
        type: ['date', 'nil'],
        warnIfNil: true,
      },
    },
    disabledDaysOfWeek: {
      type: ['array', 'nil'],
      items: {
        type: ['number', 'nil'],
        enum: [0, 1, 2, 3, 4, 5, 6],
        warnIfNil: true,
      },
    },
  },
});

const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk = composeSDKFactories<IDatePickerProps, IDatePickerSDK, any>(
  elementPropsSDKFactory,
  disablePropsSDKFactory,
  focusPropsSDKFactory,
  readOnlyPropsSDKFactory,
  clickPropsSDKFactory,
  requiredPropsSDKFactory,
  validationPropsSDKFactory,
  changePropsSDKFactory,
  ownSDKFactory,
);

export default createComponentSDKModel(sdk);
