import {
  FileType,
  BaseFileType,
  fileTypeToBaseFileType,
  fileTypeMatchers,
} from '@wix/editor-elements-corvid-utils';
import {
  FileMetaData,
  UploadStatus,
} from '../../../components/FileUploader/FileUploader.types';
import {
  ValidationData,
  FileUploaderValidationData,
  FilesValidationInfo,
  FileValidityKey,
} from './inputValidationTypes';
import {
  addErrorToValidationDataAndKeepMessage,
  getValidationMessage,
} from './validityUtils';

export const fileSizes = {
  Image: 25000000,
  Document: 1000000000,
  Video: 1000000000,
  Audio: 50000000,
} as const;

export const KB = 1000;
export const MB = KB * 1000;
export const GB = MB * 1000;

const isFileTypeValid = (fileType: BaseFileType, fileName: string) =>
  fileTypeMatchers[fileType].test(fileName);
const isFileSizeValid = (fileType: BaseFileType, fileSize: number) =>
  fileSize <= fileSizes[fileType];
export const getFileExtension = (fileName: string): string => {
  const match = /\.(\w+)$/.exec(fileName);
  return ((match && match[1]) || '').toUpperCase();
};
export const getMaxFileSize = (fileType: BaseFileType): string => {
  const bytes = fileSizes[fileType];
  if (bytes > 999999999) {
    return `${bytes / GB} GB`;
  }
  if (bytes > 999999) {
    return `${bytes / MB} MB`;
  }
  return `${bytes / KB} KB`;
};

export const getFileValidityValidationMessage = (
  validityKey: FileValidityKey,
  fileName: string,
  fileType: FileType,
): string => {
  switch (validityKey) {
    case 'fileTypeNotAllowed':
      return getValidationMessage(
        'fileTypeNotAllowed',
        getFileExtension(fileName),
      );

    case 'fileSizeExceedsLimit':
      return getValidationMessage(
        'fileSizeExceedsLimit',
        getMaxFileSize(fileTypeToBaseFileType(fileType, fileName)),
      );

    default:
      return '';
  }
};

const validateForFile = (
  fileData: FileMetaData,
  fileType: FileType,
  validationData: ValidationData,
) => {
  const fileName = fileData.name || '';
  let fileValidityKey: FileValidityKey = '';
  const currentFileType = fileTypeToBaseFileType(fileType, fileName);
  let updatedValidationData = validationData;

  if (!isFileTypeValid(currentFileType, fileName)) {
    updatedValidationData = addErrorToValidationDataAndKeepMessage(
      updatedValidationData,
      'fileTypeNotAllowed',
      getFileValidityValidationMessage(
        'fileTypeNotAllowed',
        fileName,
        currentFileType,
      ),
    );
    fileValidityKey = 'fileTypeNotAllowed';
  }

  if (!isFileSizeValid(currentFileType, fileData.size || 0)) {
    updatedValidationData = addErrorToValidationDataAndKeepMessage(
      updatedValidationData,
      'fileSizeExceedsLimit',
      getFileValidityValidationMessage(
        'fileSizeExceedsLimit',
        fileName,
        currentFileType,
      ),
    );

    if (!fileValidityKey) {
      fileValidityKey = 'fileSizeExceedsLimit';
    }
  }

  return { fileValidityKey, updatedValidationData };
};

export const validateFile = (
  props: {
    value: Array<FileMetaData>;
    uploadStatus: UploadStatus;
    fileType: FileType;
  },
  validationData: ValidationData,
): FileUploaderValidationData => {
  const { value, uploadStatus, fileType } = props;
  const filesData = value;
  const uploadNotStarted = uploadStatus === 'Not_Started';
  const filesValidationInfo: FilesValidationInfo = [];

  if (filesData && uploadNotStarted) {
    filesData.forEach(fileData => {
      const { fileValidityKey, updatedValidationData } = validateForFile(
        fileData,
        fileType,
        validationData,
      );

      validationData = updatedValidationData;
      filesValidationInfo.push(fileValidityKey);
    });
  }

  const fileUploaderValidationData: FileUploaderValidationData = {
    ...validationData,
    type: 'FileUploader' as const,
    filesValidationInfo,
  };

  return fileUploaderValidationData;
};
