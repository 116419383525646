import { SdkInstance } from '@wix/editor-elements-types';
import {
  assert,
  composeSDKFactories,
  createElementPropsSDKFactory,
  playablePropsSDKFactory,
  toJSONBase,
  registerEventOnce,
} from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import {
  clickActionSDKFactory,
  navigationButtonsSDKFactory,
  onItemClickSDKFactory,
  unsupportedCurrentItemPropsSDKFactory,
} from '../../../../core/corvid/props-factories';
import {
  IPaginatedGridGalleryProps,
  IPaginatedGridGalleryOwnSDK,
  IPaginatedGridGallerySDKFactory,
} from '../PaginatedGridGallery.types';
import {
  convertGalleryItemsToUserModel,
  convertToGalleryItemsPropsOrReport,
} from '../../../../core/corvid/galleries/GallerySDKUtils';

const paginatedGridGallerySDKFactory: IPaginatedGridGallerySDKFactory = api => {
  const {
    sdkData,
    props,
    metaData,
    setProps,
    platformUtils: { linkUtils },
    compRef,
    getSdkInstance,
    createSdkState,
  } = api;
  const [state, setState] = createSdkState<{
    navigationEndCallbacks: Array<(arg?: any) => void>;
  }>({
    navigationEndCallbacks: [],
  });

  registerEventOnce({
    eventName: 'onNavigationEnd',
    api,
    cb: () => {
      state.navigationEndCallbacks.forEach(cb => cb());
      setState({ navigationEndCallbacks: [] });
    },
  });

  return {
    next() {
      return new Promise<SdkInstance>(resolve => {
        setState({
          navigationEndCallbacks: [...state.navigationEndCallbacks, resolve],
        });
        compRef.next();
      });
    },
    previous() {
      return new Promise<SdkInstance>(resolve => {
        setState({
          navigationEndCallbacks: [...state.navigationEndCallbacks, resolve],
        });
        compRef.previous();
      });
    },
    get isPlaying() {
      const isPlaying = props.isPlaying;
      if (assert.isBoolean(isPlaying)) {
        return isPlaying;
      }

      const numberOfItemsPerPage = props.rows * props.columns;
      const numberOfPages = Math.ceil(
        props.items.length / numberOfItemsPerPage,
      );

      return props.autoPlay && numberOfPages > 1;
    },
    get items() {
      return convertGalleryItemsToUserModel(props.items, linkUtils);
    },
    set items(items) {
      const propsItems = convertToGalleryItemsPropsOrReport(
        items,
        metaData.role,
        metaData.compId,
        sdkData.dataId,
        linkUtils,
        sdkData.imageDisplayMode,
      );
      if (propsItems) {
        setProps({ items: propsItems });
      }
    },
    get galleryCapabilities() {
      return {
        hasCurrentItem: false,
        hasNavigationButtons: true,
        isAnimatable: true,
        isPlayable: true,
        supportsAllMediaTypes: false,
      };
    },
    get type() {
      return '$w.Gallery';
    },

    toJSON() {
      const { clickAction, showNavigationButtons, items } = getSdkInstance();
      return {
        ...toJSONBase(metaData),
        clickAction,
        showNavigationButtons,
        items,
        type: '$w.Gallery',
      };
    },
  };
};

const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk = composeSDKFactories<
  IPaginatedGridGalleryProps,
  IPaginatedGridGalleryOwnSDK
>(
  elementPropsSDKFactory,
  clickActionSDKFactory,
  navigationButtonsSDKFactory,
  playablePropsSDKFactory,
  onItemClickSDKFactory,
  paginatedGridGallerySDKFactory,
  unsupportedCurrentItemPropsSDKFactory,
);

export default createComponentSDKModel(sdk);
