import {
  UploadStatus,
  IFileUploaderProps,
  IFileUploaderSDKState,
} from '../../../components/FileUploader/FileUploader.types';
import { defaultState } from '../../../components/FileUploader/corvid/constants';
import { ValueValidator } from './inputValidationTypes';
import {
  addErrorToValidationDataAndKeepMessage,
  getValidationMessage,
} from './validityUtils';

const getFileMissingMessage = (
  uploadStatus: UploadStatus,
  state: Readonly<IFileUploaderSDKState>,
): string => {
  if (uploadStatus === 'Failed') {
    return state.uploadServerError || getValidationMessage('fileNotUploaded');
  }

  return getValidationMessage('fileNotUploaded');
};

export const validateUploadDone: ValueValidator<
  Pick<
    IFileUploaderProps,
    'uploadStatus' | 'required' | 'withFixNotRequiredValidation'
  >
> = (
  { uploadStatus, required, withFixNotRequiredValidation },
  validationData,
  { createSdkState },
) => {
  const isAlreadyInvalid = !validationData.validity.valid;

  const oldValidationFlow =
    uploadStatus !== 'Failed' || !withFixNotRequiredValidation;

  if ((!required && oldValidationFlow) || isAlreadyInvalid) {
    return validationData;
  }

  const [state] = createSdkState(defaultState);

  return uploadStatus === 'Done'
    ? validationData
    : addErrorToValidationDataAndKeepMessage(
        validationData,
        'fileNotUploaded',
        getFileMissingMessage(uploadStatus, state),
      );
};
