import {
  composeSDKFactories,
  createElementPropsSDKFactory,
  toJSONBase,
} from '@wix/editor-elements-corvid-utils';
import {
  clickActionSDKFactory,
  onItemClickSDKFactory,
  unsupportedCurrentItemPropsSDKFactory,
  unsupportedPlayablePropsSDKFactory,
} from '../../../../core/corvid/props-factories';
import {
  ISliderGalleryProps,
  ISliderGalleryOwnSDK,
  ISliderGallerySDKFactory,
} from '../SliderGallery.types';
import {
  convertGalleryItemsToUserModel,
  convertToGalleryItemsPropsOrReport,
  createUnsupportedAPIReporter,
} from '../../../../core/corvid/galleries/GallerySDKUtils';

const SliderGallerySDKFactory: ISliderGallerySDKFactory = api => {
  const {
    sdkData,
    props,
    metaData,
    setProps,
    platformUtils: { linkUtils },
    getSdkInstance,
  } = api;
  const reportUnsupportedAPI = createUnsupportedAPIReporter('SliderGallery');

  return {
    get items() {
      return convertGalleryItemsToUserModel(props.items, linkUtils);
    },
    set items(items) {
      const propsItems = convertToGalleryItemsPropsOrReport(
        items,
        metaData.role,
        metaData.compId,
        sdkData.dataId,
        linkUtils,
        sdkData.imageDisplayMode,
      );
      if (propsItems) {
        setProps({ items: propsItems });
      }
    },
    get showNavigationButtons() {
      return reportUnsupportedAPI('showNavigationButtons');
    },
    set showNavigationButtons(showNavigationButtons) {
      reportUnsupportedAPI('showNavigationButtons');
    },
    get galleryCapabilities() {
      return {
        isPlayable: false,
        hasCurrentItem: false,
        hasNavigationButtons: false,
        supportsAllMediaTypes: false,
        isAnimatable: true,
      };
    },
    get type() {
      return '$w.Gallery';
    },
    toJSON() {
      const { clickAction, items } = getSdkInstance();
      return {
        ...toJSONBase(metaData),
        clickAction,
        items,
        type: '$w.Gallery',
      };
    },
  };
};

const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk = composeSDKFactories<
  ISliderGalleryProps,
  ISliderGalleryOwnSDK
>(
  elementPropsSDKFactory,
  clickActionSDKFactory,
  unsupportedPlayablePropsSDKFactory,
  onItemClickSDKFactory,
  SliderGallerySDKFactory,
  unsupportedCurrentItemPropsSDKFactory,
);

export default sdk;
